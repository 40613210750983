import { Frame, Slide3, Slide4, Slide5, Slide6 } from "../components/HomePage/images";
import { image1, image2, image3, imageChurch, imageCouple, imageJan, imageJon, imageMark, imageRestaurant } from "../images/images";

export const audioData = [
  {
    id: "1",
    src: "Christina Perri feat. Steve Kazee - A Thousand Years (Part 2).mp3",
    track: "Christina Perri feat. Steve Kazee - A Thousand Years (Part 2)",
  },
  {
    id: "2",
    src: "a-gentle-breeze-189206.mp3",
    track: "A gentle breeze",
  },
  {
    id: "3",
    src: "my-love_short-177579.mp3",
    track: "My love",
  },
];

export const sectiosData = {
  template_id: 1,
  language: "am",
  // date: "2024-09-08",
  sound_path: "",
  logo_path: "",
  invitation_name: "",
  age: "",
  sections: [
    {
      id: '1',
      section_name: "wedding",
      section_number: "1",
      name_1: "Anun1",
      name_2: "Anun2",
      full_name: "Anun Azganun",
      text: "content textx text",
      location_name: 'Dvin',
      time: "11:30",
      address: "Baghramyn 1",
      address_link:
        "https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18.36",
      images: [
        {
          id: "1",
          path: imageCouple,
        },
      ],
      type:'local'
    },

    {
      id: '2',
      section_name: "wedding",
      section_number: "2",
      name_1: "Anun1",
      name_2: "Anun2",
      full_name: "Anun Azganun",
      text: localStorage.getItem('lang') === 'en' ? "Pharaon Complex (Cottage N2)" : localStorage.getItem('lang') === 'ru' ? "Комплекс Фараон (Коттедж N2)" : "Փարավոն համալիր (Քոթեջ N2)",
      time: "13:00",
      address: localStorage.getItem('lang') === 'en' ? "Baghramyan 1" : localStorage.getItem('lang') === 'ru' ? "Баграмян 1" : "ԲԱղրամյան 1",
      address_link:
        "https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18.36",
      images: [
        {
          id: "1",
          path: imageCouple,
        },
      ],
      type:'local'

    },

    {
      id: '3',
      section_name: "wedding",
      section_number: "3",
      name_1: "Anun1",
      name_2: "Anun2",
      full_name: "Anun Azganun",
      text: localStorage.getItem('lang') === 'en' ? "Church of Saint Mary the Virgin" : localStorage.getItem('lang') === 'ru' ? "Церковь Святой Марии Богородицы" : "Սուրբ Մարիամ Աստվածածին եկեղեցի", 
      time: "15:15",
      address:  localStorage.getItem('lang') === 'en' ? "Yerevan, Armenakyan 225" : localStorage.getItem('lang') === 'ru' ? "г. Ереван, Арменакяна 225" : "Երևան, Արմենակյան 225",
      address_link:
        "https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18.36",
      images: [
        {
          id: "1",
          path: imageChurch,
        },
      ],
      type:'local'

    },

    {
      id: '4',
      section_name: "wedding",
      section_number: "4",
      name_1: "Anun1",
      name_2: "Anun2",
      full_name: "Anun Azganun",
      text:  localStorage.getItem('lang') === 'en' ? "Florence Restaurant" : localStorage.getItem('lang') === 'ru' ?  "Ресторан Florence" : "Ֆլորենս ռեստորան",
      time: "17:00",
      address: localStorage.getItem('lang') === 'en' ? "Yerevan. Barbus 64/2" : localStorage.getItem('lang') === 'ru' ? "г. Ереван. Барбюса 64/2" : "Երևան. Բարբյուսա 64/2",
      address_link:
        "https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18.36",
      images: [
        {
          id: "1",
          path: imageRestaurant,
        },
      ],
      type:'local'

    },

    {
      id: '5',
      section_name: "wedding",
      section_number: "5",
      name_1: "Anun1",
      name_2: "Anun2",
      full_name: "Anun Azganun",
      text:  localStorage.getItem('lang') === 'en' ? "Florence Restaurant" : localStorage.getItem('lang') === 'ru' ?  "Ресторан Florence" : "Ֆլորենս ռեստորան",
      time: "19:00",
      address: localStorage.getItem('lang') === 'en' ? "Yerevan. Barbus 64/2" : localStorage.getItem('lang') === 'ru' ? "г. Ереван. Барбюса 64/2" : "Երևան. Բարբյուսա 64/2",
      address_link:
        "https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18.36",
      images: [
        {
          id: "1",
          path: imageRestaurant,
        },
      ],
      type:'local'

    },
  ],
};

// =====================banerData======================

export const sliderData = [
  { id: 1, img: Frame, title: "Welcome To Our Gift Shop", description: "Sequi perspiciatis nulla reiciendis, rem, tenetur impedit, eveniet non necessitatibus error distinctio mollitia suscipit." },
  { id: 3, img: Slide3, title: "Shop Now", description: "Perspiciatis enim, accusantium perferendis." },
  { id: 4, img: Slide4, title: "Shop Now", description: "Perspiciatis enim, accusantium perferendis." },
  { id: 5, img: Slide5, title: "Shop Now", description: "Perspiciatis enim, accusantium perferendis." },
  { id: 6, img: Slide6, title: "Shop Now", description: "Perspiciatis enim, accusantium perferendis." },
];



export const commentData = [
  {
    id: '1',
    name: 'Mark Webber',
    img: image1,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam scelerisque aliquam odio et faucibus. Nulla rhoncus feugiat eros quis consectetur. Quisque consectetur...',
  },

  {
    id: '2',
    name: 'Jon Smith',
    img: image2,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam scelerisque aliquam odio et faucibus. Nulla rhoncus feugiat eros quis consectetur. Quisque consectetur...',
  },

  {
    id: '3',
    name: 'Jan Wang',
    img: image3,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam scelerisque aliquam odio et faucibus. Nulla rhoncus feugiat eros quis consectetur. Quisque consectetur...',
  },

]