import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { allInfoSelector, changeInfoBanquet_imgs, changeInfoBanquetAddres, changeInfoBanquetText, changeInfoBanquetTime, changeInfoSelector } from '../../../store/slices/ChangeInfoSlice/ChangeInfoSlice';
import { cake, imageBride } from '../../../images/images';
import { openLinkModal } from '../../../store/slices/LinkModalSlice/LinkModalSlice';
import { editIcon } from '../../../iconsFolder/icons';
import { convertToBase64, customBasesUrlFunc } from '../../../utils/helperFunck';
import TutorialVideo from '../../TutorialVideo/TutorialVideo';

function Wedding1Bonquete({ item, handleDelSection }) {
  const { t, i18n } = useTranslation();
  const [banquetItem, setBanquetItem] = useState(false);
  const [banquetTime, setBanquetTime] = useState('');
  const [banquetText, setBanquetText] = useState('');
  const [banquetAddress, setBanquetAddress] = useState('');
  const [banquetAddressLink, setBanquetAddressLink] = useState('');
  const [file, setFile] = useState('');
  const banquetRef = useRef(null);
  const allInfo = useSelector(allInfoSelector);
  const changeInfoState = useSelector(changeInfoSelector);
  const dispatch = useDispatch();
  const params = customBasesUrlFunc();



  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (banquetRef.current && offset > banquetRef.current.offsetTop - 500) {
        setBanquetItem(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    dispatch(changeInfoBanquetTime(banquetTime));
  }, [banquetTime]);


  useEffect(() => {
    dispatch(changeInfoBanquetText(banquetText));
  }, [banquetText])

  useEffect(() => {
    dispatch(changeInfoBanquetAddres(banquetAddress));
  }, [banquetAddress]);

  function handleChange(e) {
    setFile(e.target.files[0]);
  }

  useEffect(() => {

    if (file) {
      const fileBlob = new Blob([file], { type: file.type });
      dispatch(changeInfoBanquet_imgs(fileBlob));
    }
  }, [file, dispatch]);


  console.log(item, 'ggh');

  return (
    <div className='banquet' ref={banquetRef} style={{backgroundColor: (item.type === 'local' && changeInfoState === 'edit') ? 'rgba(135, 101, 65, 0.328)' : ''}}>
      {
        banquetItem &&
        <div className='marriage_text right'>
          <div className='image_cake' >
            <img src={cake} alt="not found" />
          </div>
          <div className='about'>
            <span className='marriage_title'>{t('restaurant.0')}</span>
            {/* <span>{item.time}</span>
              <span>{item.text}</span>
              <span>{item.address}</span> */}
            {changeInfoState === 'edit' ? <input type="time" value={allInfo.banquetTime} placeholder='Ժամ' className='event_inp' onChange={(e) => setBanquetTime(e.target.value)} /> : <span>{allInfo.banquetTime || item.time}</span>}
            {changeInfoState === 'edit' ? <input type="text" value={allInfo.banquetText} placeholder={t('placeholderWedding1.2')} className='event_inp' onChange={(e) => setBanquetText(e.target.value)} /> : <span>{allInfo.banquetText || item.text}</span>}
            {changeInfoState === 'edit' ? <input type="text" value={allInfo.banquetAddres} placeholder={t('placeholderWedding1.3')} className='event_inp' onChange={(e) => setBanquetAddress(e.target.value)} /> : <span>{allInfo.banquetAddres || item.address}</span>}
          </div>

          <div className='marriage_button_link_div'>
            {
              item.address_link && <a href={item.address_link} target='_blank'>
                <button className='get even' onClick={() => !params.token && dispatch(openLinkModal('banquet'))}>{t('restaurant.3')}</button>

              </a>
            }

            {
              item?.type === 'local' && <TutorialVideo />
            }
          </div>
        </div>
      }
      {
        banquetItem &&
        <div className='image_marriage left'>
          {changeInfoState === 'edit' && <label onChange={(e) => handleChange(e)}>
            <input style={{ display: 'none' }} type="file" />
            <span className='wedding1_edite_image'>{editIcon}</span>
          </label>}
          {
            changeInfoState && file ? <img src={URL.createObjectURL(file)} alt="wedding1" /> :
              item?.images.map((img, index) => {
                return <img key={index} src={img.path} alt="not found" />
              })
          }
        </div>
      }

        {item.type === 'local' && changeInfoState === 'edit' && (
                <span className='wedding1_event_xMark' onClick={()=> handleDelSection(item.id)}>X</span>
            )}
    </div>
  )
}

export default Wedding1Bonquete