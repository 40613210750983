import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { allInfoSelector, changeInfoEvent_imgs, changeInfoEventAddres, changeInfoEventText, changeInfoEventTime, changeInfoSelector } from '../../../../store/slices/ChangeInfoSlice/ChangeInfoSlice';
import { useTranslation } from 'react-i18next';
import { editIcon, ringsWedding } from '../../../../iconsFolder/icons';
import { imageCouple } from '../../../../images/images';
import { openLinkModal } from '../../../../store/slices/LinkModalSlice/LinkModalSlice';
import { convertToBase64, customBasesUrlFunc, setBase64 } from '../../../../utils/helperFunck';
import TutorialVideo from '../../../TutorialVideo/TutorialVideo';

function Wedding1Event({ item, handleDelSection }) {
    const [eventItem, setEventItem] = useState(false);
    const eventRef = useRef(null);
    const { t, i18n } = useTranslation();
    const allInfo = useSelector(allInfoSelector);
    const changeInfoState = useSelector(changeInfoSelector);
    const [event1Text, setEvent1Text] = useState(allInfo.eventText)
    const [eventTime, setEventTime] = useState(allInfo.eventTime)
    const [eventAddres, setEventAddress] = useState(allInfo.eventTime)
    const [file, setFile] = useState([]);
    const dispatch = useDispatch();
    const params = customBasesUrlFunc();


    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (eventRef.current) {
                if (offset > eventRef.current.offsetTop - 500) {
                    setEventItem(true);
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        dispatch(changeInfoEventText(event1Text))
    }, [event1Text])

    useEffect(() => {
        dispatch(changeInfoEventTime(eventTime))
    }, [eventTime])

    useEffect(() => {
        dispatch(changeInfoEventAddres(eventAddres))
    }, [eventAddres])


    function handleChange(e) {
           setFile([...file, e.target.files[0]]);
    }

    function handleDelete(index) {
        const updatedFiles = file.filter((_, i) => i !== index);
        setFile(updatedFiles);
    }

    useEffect(() => {
        if (file.length > 0) {
            // const fileBlob = new Blob([file], { type: file.type });
            // dispatch(changeInfoEvent_imgs(fileBlob));

            file.map((img) => {
                const fileBlob = new Blob([img], { type: img.type });
                dispatch(changeInfoEvent_imgs(fileBlob));
            })
        }
      }, [file, dispatch]);


      

    return (
        <div className='event' ref={eventRef} style={{backgroundColor: (item.type === 'local' && changeInfoState === 'edit') ? 'rgba(135, 101, 65, 0.328)' : ''}}>
            {
                eventItem &&
                <div className='image_marriage right'>
                    {changeInfoState === 'edit' && <label onChange={(e) => handleChange(e)}>
                        <input style={{ display: 'none' }} type="file" />
                        <span className='wedding1_edite_image'>{editIcon}</span>
                    </label>}
                    {
                      changeInfoState && file.length > 0  ? file.map((img, index) => <div key={index} className='wedding1_img_div'>
                            <img className='wedding1_img' src={URL.createObjectURL(img)} alt="wedding1_img" />
                            <span onClick={()=> handleDelete(index)}  className='wedding1_xMark_image'>X</span>
                      </div>) :
                      item.images && item.images.map((img,index) => <img key={index} className='wedding1_img' src={img.path} alt="wedding1_img" />)      
                    }
                </div>
            }
            {
                eventItem &&
                <div className='marriage_text left'>
                    <span className='icon_marriage_ring'>{ringsWedding}</span>
                    <div className='about'>
                        <span className='marriage_title'>{t('party.0')}</span>
                        <span className='marriage_title'>{t('party.3')}</span>
                        {changeInfoState === 'edit' ? <input type="time" className='time_inp event_inp' value={allInfo?.eventTime} onChange={(e) => setEventTime(e.target.value)} /> : <span>{allInfo?.eventTime || item.time}</span>}
                        {changeInfoState === 'edit' ? <input type="text" className='text_inp event_inp' value={allInfo?.eventText} onChange={(e) => setEvent1Text(e.target.value)} placeholder={t('placeholderWedding1.2')} /> : <span>{allInfo?.eventText || item.text}</span>}
                        {changeInfoState === 'edit' ? <input type="text" className='address_inp event_inp' value={allInfo?.eventAddres} onChange={(e) => setEventAddress(e.target.value)} placeholder={t('placeholderWedding1.3')} /> : <span>{allInfo?.eventAddres || item.address}</span>}
                    </div>
                   <div className='marriage_button_link_div'>
                        {
                            item.address_link && <a href={item.address_link} target='_blank'>
                                <button className='get odd' onClick={() => !params.token &&  dispatch(openLinkModal('event'))}>{t('party.2')}</button>
                                
                            </a>
                        }

                        {
                            item?.type === 'local' && <TutorialVideo />
                        }
                   </div>
                </div>
            }

             {item.type === 'local' && changeInfoState === 'edit' && (
                <span className='wedding1_event_xMark' onClick={()=> handleDelSection(item.id)}>X</span>
            )}
        </div>
    )
}

export default Wedding1Event